import * as React from "react"
import {Link} from 'gatsby'
import Fade from 'react-reveal/Fade';
import Logo from '../images/logos/5thStrand-Cutout.svg'
import Layout from '../components/layout'
import PinkFullCircle from '../images/pink-full-circle.svg'
import YellowFullCircle from '../images/yellow-full-circle.svg'

import WaterfordProject from '../images/waterford-project-circle.svg'
import BananaProject from '../images/banana-project.svg'
import ComkitProject from '../images/comkit-project.svg'
import ZooProject from '../images/zoo-project.svg'



import CustomCursor from 'custom-cursor-react';
import 'custom-cursor-react/dist/index.css';

var ReactRotatingText = require('react-rotating-text');

const Projects = () => {
  return (

    <Layout>
      <CustomCursor
      targets={['.link', '.your-css-selector']}
      customClass='custom-cursor'
      dimensions={50}
      fill='#222'
      opacity={1}
      smoothness={{
        movement: 0.2,
        scale: 0.1,
        opacity: 1,
      }}
      targetOpacity={0.5}
    />
    <main className="cont bg-white nocursor scroll-smooth	">
      <div className="child sm:text-center flex justify-center items-center relative">
        <div className="w-11/12">
          <h1 className="leading-loose font-bold text-black text-4xl lg:text-8xl font-heading pb-6">
          HAVE A WANDER DOWN OUR<br/><span className=" border-b-2 border-black">MEMORY LANE</span><span className="text-5th-yellow"> .</span>
          </h1>
        </div>

        <div className="top-0 left-0 absolute w-24 md:w-36">
          <img className="w-full transition ease-in-out hover:-translate-y-8 duration-300" src={PinkFullCircle}/>
        </div>

        <div className="top-0 right-0 absolute w-24 md:w-36">
          <img className="w-full transition ease-in-out hover:-translate-y-8 duration-300" src={YellowFullCircle}/>
        </div>

        <div className="bottom-10 right-10 absolute ">
          <p className="text-5th-pink">PART OF THE <a target="_blank" href="https://urbanscaleinterventions.com/"><span className="link text-black">USI</span></a> FAMILY</p>
        </div>
      </div>

      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left block md:hidden">
        <div className="w-6/6">
          <Fade bottom>
          <img className="w-full pl-16" src={WaterfordProject}/>
          </Fade>
          <h2 className="w-5/6 font-heading text-2xl lg:text-6xl py-4 pl-6">WATERFORD DIGITAL STORY TRAIL <span className="text-5th-cyan">.</span></h2>
        </div>
       
      </div> 

      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left">
        <div className="w-5/6 md:grid md:grid-cols-2">
          <div className="text-black font-heading pt-16 lg:pt-0">
            <h2 className="text-4xl lg:text-6xl pb-4 hidden md:block">WATERFORD DIGITAL STORY TRAIL <span className="text-5th-cyan">.</span></h2>
            <p className="text-xl z-10 pb-4 font-lower">
            Waterford Digital Story Trail is a web-based application that takes users on a historical journey through the ancient streets of Waterford. It uses immersive augmented reality pieces and crafted narrations to tell the tall tales and amazing hidden stories from all eras of Waterford’s history.  
            </p>
            <p className="text-md pb-4 font-lower">Services: Research, Content Creation, UI and Graphic Design, Development, Hosting & Maintenance.</p>
            <h3 className="text-md pb-8 font-lower">Client: Waterford City Council</h3>
            <a href="https://digitalstorytrail.visitwaterford.com/" target="_blank" className="uppercase font-lower link border-2 rounded-full border-black p-2 bg-5th-cyan hover:bg-black hover:text-white transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-110">Visit site</a>
          </div>

        </div>

        <div className="right-0 absolute w-48 lg:w-96 z-10 invisible md:visible">
          <Fade bottom>
          <img className="w-full" src={WaterfordProject}/>
          </Fade>
        </div>
       
      </div> 


      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left block md:hidden">
        <div className="w-6/6">
          <Fade bottom>
          <img className="w-full" src={BananaProject}/>
          </Fade>
          <h2 className="w-5/6 font-heading text-2xl lg:text-6xl py-4 pl-6">BANANA BLOCK <span className="text-5th-yellow">.</span></h2>
        </div>
       
      </div>
      

       <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left">
        <div className="w-5/6 md:grid md:grid-cols-2 pt-16 lg:pt-0">
          <div></div>
          <div className="text-black font-heading">
            <h2 className="text-4xl lg:text-6xl pb-4 hidden md:block">BANANA BLOCK <span className="text-5th-yellow">.</span></h2>
            <p className="text-xl z-10 pb-4 font-lower">
            Banana Block is a new living museum and events space set within a historic linen mill and inspired by the curious connections between Belfast and bananas. Peel the banana a little further and you’ll find a space for original ideas, creative learning and new connections. This website incorporated a CMS to allow edits without interference.</p>
            <p className="text-md pb-4 font-lower">Services: Content Creation, UI and Graphic Design, Development, CMS Implementation, Hosting & Maintenance.</p>
            <h3 className="text-md pb-8 font-lower">Client: Banana Block</h3>
            <a href="https://bananablock.org/" target="_blank" className="uppercase font-lower link border-2 rounded-full border-black p-2 bg-5th-yellow hover:bg-black hover:text-white transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-110">Visit site</a>
          </div>

        </div>

        <div className="left-0 absolute w-48 lg:w-96 z-10 invisible md:visible">
          <Fade bottom>
          <img className="w-full" src={BananaProject}/>
          </Fade>
        </div>
       
      </div>     


      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left block md:hidden">
        <div className="w-6/6 pl-6">
          <Fade bottom>
          <img className="w-full" src={ComkitProject}/>
          </Fade>
          <h2 className="w-5/6 font-heading text-2xl lg:text-6xl py-4 pl-6">COMKIT <span className="text-5th-cyan">.</span></h2>
        </div>
       
      </div> 
 

      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left">
        <div className="w-5/6 md:grid md:grid-cols-2 pt-16 lg:pt-0">
          <div className="text-black font-heading">
            <h2 className="text-4xl lg:text-6xl pb-4 hidden md:block">COMKIT <span className="text-5th-cyan">.</span></h2>
            <p className="text-xl z-10 pb-4 font-lower">
            Comkit is a digital platform built to help people. During a time of heightened suicide concern its natural to feel lost and overwhelmed. The aim of Comkit is to guide you through the process and help you better understand every step, in how to remember a loved one and how to support those in need.  
            </p>
            <p className="text-md pb-4 font-lower">Services: Content Creation, UI and Graphic Design, Development, Hosting & Maintenance.</p>
            <h3 className="text-md pb-8 font-lower">Client: Public Health Agency, Department for Health&nbsp;NI</h3>
            <a href="https://comkit.info/" target="_blank" className="uppercase font-lower link border-2 rounded-full border-black p-2 bg-5th-cyan hover:bg-black hover:text-white transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-110">Visit site</a>
          </div>

        </div>

        <div className="right-0 absolute  w-48 lg:w-96 z-10 invisible md:visible">
          <Fade bottom>
          <img className="w-full" src={ComkitProject}/>
          </Fade>
        </div>
       
      </div>   


      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left block md:hidden">
        <div className="w-6/6">
          <Fade bottom>
          <img className="w-full" src={ZooProject}/>
          </Fade>
          <h2 className="w-5/6 font-heading text-2xl lg:text-6xl py-4 pl-6">BELFAST ZOO <span className="text-5th-cyan">.</span></h2>
        </div>
       
      </div>
      

       <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left">
        <div className="w-5/6 md:grid md:grid-cols-2 pt-16 lg:pt-0">
          <div></div>
          <div className="text-black font-heading">
            <h2 className="text-4xl lg:text-6xl pb-4 hidden md:block">BELFAST ZOO <span className="text-5th-cyan">.</span></h2>
            <p className="text-xl z-10 pb-4 font-lower">
            Our recent work with Belfast Zoo saw us explore new research territories to create an ambitious vision for the future of this regional asset. The site to accompany this report was designed accordingly, with vibrant colours and motion throughout to represent the wildlife and thriving flora and fauna within the zoo.</p>
            <p className="text-md pb-4 font-lower">Services: Content Creation, UI and Graphic Design, Development, CMS Implementation, Hosting & Maintenance.</p>
            <h3 className="text-md pb-8 font-lower">Client: Belfast City Council</h3>
            <a href="https://belfastrezoo.org/" target="_blank" className="uppercase font-lower link border-2 rounded-full border-black p-2 bg-5th-cyan hover:bg-black hover:text-white transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-110">Visit site</a>
          </div>

        </div>

        <div className="left-0 absolute w-48 lg:w-96 z-10 invisible md:visible">
          <Fade bottom>
          <img className="w-full" src={ZooProject}/>
          </Fade>
        </div>
       
      </div>  



      

      


      <footer className="snap-always snap-center p-4 sm:p-6 my-6 lg:my-16 mx-auto w-5/6 font-lower">
            <div className="md:flex md:justify-between items-center">
                <div className="mb-6 md:mb-0">
                    <Link to="/" className="flex items-center">
                        <img src={Logo} className="h-36" alt="5th Strand Logo"/>
                    </Link>
                </div>
                <div className="mb-6 md:mb-0">
                  <h1 className="text-black text-2xl sm:text-3xl md:text-4xl leading-relaxed font-bold ">5th Strand<br/><span className='font-normal leading-relaxed'>Believers in Change</span></h1>
                </div>
                <div className="grid grid-cols-2 gap-8 sm:gap-8">
                   
                   
                    <div>
                        <h2 className="mb-6 text-xs font-semibold text-black uppercase">Legal</h2>
                        <ul className="text-black text-xs">
                            <li className="mb-4">
                                <Link to="/privacy" className="hover:underline">Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h2 className="mb-6 font-semibold text-black uppercase text-xs">Contact</h2>
                        <ul className="text-black text-xs">
                            <li className="mb-4">
                                <a href="mailto:studio@5thstrand.com" className="hover:underline text-xs">studio@5thstrand</a>
                            </li>
                            <li>
                                <a href="tel:+447754078144" className="hover:underline">Tel: 077 540 78144</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
                <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm text-black sm:text-center dark:text-gray-400">© 2022 <a href="" className="hover:underline">5th Strand USI</a>. All Rights Reserved.
                    </span>
                 
                </div>
        </footer>
    
    </main>
    </Layout>
  )
}

export default Projects

export function Head() {
  return (
    <>
      <title>5th Strand - Projects</title>
      <meta name="description" content="5th Strand has experience in all levels of creating websites and applications." />
        <meta property="og:title" content="5th Strand - Projects" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.5thstrand.com/projects" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    </>  
    )
}